//Cherryl Susara, WNM 820 Technical Implementation, Directed Study
//Reference: React Forms https://reactjs.org/docs/forms.html
//Mozilla for HTML Datepicker https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
//W3 Cards https://www.w3schools.com/howto/howto_css_cards.asp
//Uploading React to Server https://prolevelprogramming.com/how-to-host-react-web-app-bluehost/
import React, { Component } from 'react';
import"./habitgroovy.css";
import Logo from "./habitgroovylogo.png";



class App extends Component{

 
  constructor (props) {
    super(props)
    this.state = {value:''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  
  }

  handleChange(event) {
    this.setState({
      value:event.target.value});
  }

  handleSubmit(event){
    alert('Congratulations! You set up a habit program. Your robot will play lights and sounds on scheduled routine. ' + this.state.value);
    event.preventDefault();
    }
    

  render() {
    return (




<div class="row">
<div class='topnav'>
<div class='topnav-right'>
<a class ="active" href="https://www.habitgroovy.com" > Back to Habit Groovy Page </a>
</div>
</div>

<div class="containermain">

<div class="container">

<div class="card">

<div class="program"> Build a Habit Program</div>
<h2><img src={Logo} class="logo" alt="logo" /></h2>

<form onSubmit={ this.handleSubmit}>
            
<fieldset>
    
<label class="label">What habit would you like to build?</label>
<input type="text" id="habitprogram" name="habitprogram" value={this.state.value} onChange={this.handleChange} />
        
<label class="label">Routine Starts 
<input type="date" id="startdate" name="startdate" /> 
<input type="time"id="starttime" name="starttime" /> </label>


<label class="label">Routine Ends
<input type="date" min="2020-04-01" max="2025-12-31" id="enddate" name="enddate" /> 
<input type="time" id="endtime" name="endtime" />
</label>



          

<label class="label">Frequency:</label>


<select id="choose" id="frequency" name="frequency">
    <option selected>Choose...</option>
    <option value="1">Daily</option>
    <option value="2">Every Other Day</option>
</select> 
<label class="label2">For Habit to take shape, you will need
a minimum of 18 days of repeated routine performance. Your routine
should have a start date and time then an end date and time, along with frequency. </label>

</fieldset>

       
<div class="cardspace"></div>
<input type="submit" value="Set This Program" />
<div class="cardspace"></div>
</form>
        
      
<div class="cardspace"></div>
</div>
</div>
</div>
</div>
  
    
    );
  }
  
}

export default App;